@include media-breakpoint-up(sm) {
	.col-md-12>.post-grid,
	.col-lg-12>.post-grid,
	.container>.post-grid,
	[class="col-xs-12"]>.post-grid,
	[class="col-12"]>.post-grid{
		width: 400px;
	}
}
.post-grid {
	figure {
		background: #f7e8d3;
		height: auto;
		aspect-ratio: 3/2;
		&:hover {
			img {
				transition: all 400ms ease-in-out;
				transform: scale(1.1);
			}
			img.placeholder-post {
				animation: rotateAnimation 500ms infinite alternate;
			}
		}
		img {
			object-fit: cover;
			transition: all 400ms ease-in-out;
		}
		figcaption {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 100;
			width: 100%;
			padding: .5rem;
			background-color: rgba(0,0,0,.65);
			.h3 {
				font-size: 1rem;
				line-height: 1;
				margin: 0;
				padding: 0;
				color: white;
				@include media-breakpoint-up(md) {
					font-size: 1.1rem
				}
				span {
					font-size: 75%;
					display: block;
					font-weight: 400;
				}
			}
			strong, b, i {
				color: white;
			}
		}
		.ort_label {
			transform: (translateY(-100%));
			position: absolute;
			top: 0;
			left: 0;
			color: white;
			font-size: .75rem;
		}
	}
}

@keyframes rotateAnimation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(10deg);
    }
}