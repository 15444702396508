.form-floating {
	label {
		color: black;
		font-size: 1rem;
		font-weight: bold;
	}
	.audio-record {
		position: absolute;
		right: 1rem;
		top: 1rem;
		font-size: 1.25rem;
	}
}

.form-control {
	border: solid 1px grey;
	&:focus {
		border: solid 2px var(--bs-primary);
		box-shadow: none;
	}
}

// Fake Textarea
.textarea {
    border: solid 1px black;
    border-radius: 3px;
    padding: 1rem!important;
    pre {
    	margin-bottom: 0;
    }
}

.form-floating.input_textarea label:before {
	background: none;
	box-shadow: none;
}

.form-select:disabled {
    color: lightgrey;
}

input[type=range] {
		-webkit-appearance: none;
		margin: 10px 0;
		width: 100%;
}

input[type=range]:focus {
		outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: 10px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: 0px 0px 0px;
		background: #dfdfdf;
		border-radius: 3px;
		border: 0px solid;
}

input[type=range]::-webkit-slider-thumb {
		box-shadow: 0px 0px 1px;
		border: 0px solid;
		height: 17px;
		width: 17px;
		border-radius: 3px;
		background: var(--bs-primary);
		cursor: pointer;
		-webkit-appearance: none;
		margin-top: -3.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
		background: #dfdfdf;
}

input[type=range]::-moz-range-track {
		width: 100%;
		height: 10px;
		cursor: pointer;
		animate: 0.2s;
		box-shadow: 0px 0px 0px;
		background: #dfdfdf;
		border-radius: 3px;
		border: 0px solid;
}

input[type=range]::-moz-range-thumb {
		box-shadow: 0px 0px 1px;
		border: 0px solid;
		height: 17px;
		width: 17px;
		border-radius: 3px;
		background: var(--bs-primary);
		cursor: pointer;
}

input[type=range]::-ms-track {
		width: 100%;
		height: 10px;
		cursor: pointer;
		animate: 0.2s;
		background: transparent;
		border-color: transparent;
		color: transparent;
}

input[type=range]::-ms-fill-lower {
		background: #dfdfdf;
		border: 0px solid;
		border-radius: 6px;
		box-shadow: 0px 0px 0px;
}

input[type=range]::-ms-fill-upper {
		background: #dfdfdf;
		border: 0px solid;
		border-radius: 6px;
		box-shadow: 0px 0px 0px;
}

input[type=range]::-ms-thumb {
		box-shadow: 0px 0px 1px;
		border: 0px solid;
		height: 17px;
		width: 17px;
		border-radius: 3px;
		background: var(--bs-primary);
		cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
		background: #dfdfdf;
}

input[type=range]:focus::-ms-fill-upper {
		background: #dfdfdf;
}

/* error message formatting */
.alert-danger ul{
	margin-bottom: 0;
}