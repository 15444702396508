//IMAGES IMG
.text-img {
	width: 45%;
	float: left;
	clear: left;
	margin: 1em;
	margin-left: 0;
	margin-top: 2%;
	@include media-breakpoint-up(sm) {
		clear: right;
		float: right;
		margin: 1em;
		margin-top: 1%;
		margin-right: 0;
		width: 280px;
	}
	@include media-breakpoint-up(md) {
		width: 310px;
	}
	img {
        border-radius: 0.375rem;
	    overflow: hidden;
	}
	figcaption {
        margin-top: -0.25rem;
        font-size: .8rem;
        text-align: center;
	}
}