:root {
  --bs-primary: #273955;
  --bs-primary-hover: #36517D;
  --bs-secondary: #b72515;
  --bs-secondary-hover: #e5331f;
  --bs-primary-rgb: 39,57,85;
  --bs-primary-rgb-hover: 54, 81, 125;
  --bs-secondary-rgb: 183,37,21;
  --bs-secondary-rgb-hover: 229, 51, 31;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: black;
  --bs-body-color-rgb: 0, 0, 0;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: var(--bs-secondary);
  --bs-link-color-rgb: var(--bs-secondary-rgb);
  --bs-link-decoration: underline;
  --bs-link-hover-color: var(--bs-secondary-hover);
  --bs-link-hover-color-rgb: var(--bs-secondary-rgb-hover);
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-hover);
  --bs-btn-hover-border-color: var(--bs-primary-hover);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FFA2C3;
  --bs-btn-disabled-border-color: #FFA2C3;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-secondary-hover);
  --bs-btn-hover-border-color: var(--bs-secondary-hover);
  --bs-btn-focus-shadow-rgb: 130,138,145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-secondary);
  --bs-btn-disabled-border-color: var(--bs-secondary);
}

.list-group {
  --bs-list-group-active-bg: var(--bs-primary);
  --bs-list-group-active-border-color: var(--bs-primary);
  --bs-list-group-item-padding-y: 0.4rem;
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="var%28--bs-body-color%29"%3e%3cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23ffffff"%3e%3cpath fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: var(--bs-primary);
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: white;
  --bs-accordion-active-bg: var(--bs-primary);
}

.list-group-item,
.card {
  border: none;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
}

.form-floating.input_textarea label {
  min-width: 90%;
}

.form-floating.input_textarea label::before {
  content: "";
  position: absolute;
  top: 0.9em;
  z-index: -1;
  width: 100%;
  height: 1.2em;
  background-color: white;
  box-shadow: 0 0 8px 4px #ffffff;
}

.form-floating.input_textarea > .form-control:focus ~ label, .form-floating.input_textarea > .form-control:not(:placeholder-shown) ~ label, .form-floating.input_textarea > .form-select ~ label {
  opacity: 0.95;
  color: gray;
}

html {
  scroll-behavior: smooth;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-size: 0.6rem;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

img {
  width: 100%;
  height: auto;
}

/*
IE11 fixes
==================================================
Contains specific browser fixes which are only applied in IE.
*/
html.is-ie .input-group-append .icon {
  display: block;
}
html.is-ie .input-group-append svg {
  top: 0;
  vertical-align: auto;
}
html.is-ie .cart-item-row .cart-item-quantity {
  z-index: 1;
}
html.is-ie .product-image-wrapper {
  flex-basis: auto;
}
html.is-ie .product-image-link {
  display: block;
}
html.is-ie .product-image {
  margin: auto;
}

body {
  font-family: sans-serif;
}

p {
  line-height: 1.7;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

strong, b {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500 !important;
  font-family: sans-serif;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, h6, .h5, .h6 {
  font-size: 1.2rem;
}

ol, ul {
  padding-left: 0;
}

.required::after {
  content: " *";
  color: var(--bs-danger);
}

.grecaptcha-badge {
  visibility: hidden;
}

.amazonbox {
  position: relative;
  margin: 2.25rem auto;
  width: 100%;
}
@media (min-width: 992px) {
  .amazonbox {
    margin: 2.25rem auto;
    width: 75%;
  }
}
.amazonbox:before {
  content: "Unsere Empfehlung";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bs-secondary);
  color: white;
  z-index: 999;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 3px;
}
.amazonbox .amazonbox_image {
  border: none;
  max-height: 125px;
}
@media (min-width: 992px) {
  .amazonbox .amazonbox_image {
    max-height: 200px;
  }
}
.amazonbox .amazonbox_image img {
  width: auto;
  max-height: 100px;
  margin: 0 auto;
}
@media (min-width: 992px) {
  .amazonbox .amazonbox_image img {
    max-height: 150px;
    max-width: 85%;
  }
}
.amazonbox .amazonbox_text strong {
  display: block;
  padding: 0;
  font-size: 0.9rem;
  font-weight: bold;
}
@media (min-width: 992px) {
  .amazonbox .amazonbox_text strong {
    font-size: 0.85rem;
  }
}
.amazonbox .amazonbox_text p {
  font-size: 0.8rem;
  line-height: 1.3;
}
@media (min-width: 992px) {
  .amazonbox .amazonbox_text p {
    font-size: 0.75rem;
  }
}
.amazonbox .amazonbox_text a.btn {
  font-size: 0.75rem;
}

@media (min-width: 992px) {
  .col-md-6 .amazonbox {
    width: 95%;
    min-height: 250px;
  }
}

.prio-badge {
  font-size: 0.75rem;
  right: -50px !important;
  top: -15% !important;
  left: unset !important;
}

ul.breadcrumbs {
  list-style-type: none;
  z-index: 99;
  position: relative;
  display: inline-block;
  font-size: 1rem;
}
ul.breadcrumbs li {
  display: inline-block;
  float: left;
  color: var(--bs-secondary);
  font-weight: 700;
}
ul.breadcrumbs li:first-child a {
  padding-left: 0;
}
ul.breadcrumbs li a {
  padding: 0 0.3em;
  color: var(--bs-secondary);
}
ul.breadcrumbs li a:hover {
  text-decoration: underline;
}

.description h1, .description h2, .description h3, .description h4, .description h5, .description h6,
.description .h1, .description .h2, .description .h3, .description .h4, .description .h5, .description .h6,
#preparation h1,
#preparation h2,
#preparation h3,
#preparation h4,
#preparation h5,
#preparation h6,
#preparation .h1,
#preparation .h2,
#preparation .h3,
#preparation .h4,
#preparation .h5,
#preparation .h6 {
  line-height: 1.25;
  margin-bottom: 1rem;
}
.description figcaption .h6,
#preparation figcaption .h6 {
  margin-bottom: 0;
}
.description figure,
#preparation figure {
  border-radius: var(--bs-border-radius);
  overflow: hidden;
}
.description figure figcaption,
#preparation figure figcaption {
  --bs-bg-opacity: 1;
  --bs-text-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
.description table,
#preparation table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.description table > :not(caption) > * > *,
#preparation table > :not(caption) > * > * {
  padding: 0.5rem 0;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.description ul, .description ol,
#preparation ul,
#preparation ol {
  margin: 1rem 0;
  list-style-position: inside;
}
.description ul li, .description ol li,
#preparation ul li,
#preparation ol li {
  margin-bottom: 0.5rem;
}
.description ul li p, .description ol li p,
#preparation ul li p,
#preparation ol li p {
  display: inline;
}
.description img,
#preparation img {
  margin-bottom: 1rem;
}
.description img.pswp-gallery__trigger,
#preparation img.pswp-gallery__trigger {
  cursor: zoom-in;
}
.description figure img,
#preparation figure img {
  margin-bottom: 0;
}

.tooltip-inner {
  text-align: left;
  background-color: var(--bs-primary);
  max-width: 320px;
}
@media (min-width: 992px) {
  .tooltip-inner {
    max-width: 450px;
  }
}

.feedback-btn {
  position: fixed;
  top: 50%;
  left: calc(100% - 75px);
  transform: rotate(-90deg);
  height: auto;
  width: auto;
  z-index: 10;
  white-space: nowrap;
  display: inline-block;
}

.card {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}

.form-floating label {
  color: black;
  font-size: 1rem;
  font-weight: bold;
}
.form-floating .audio-record {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.25rem;
}

.form-control {
  border: solid 1px grey;
}
.form-control:focus {
  border: solid 2px var(--bs-primary);
  box-shadow: none;
}

.textarea {
  border: solid 1px black;
  border-radius: 3px;
  padding: 1rem !important;
}
.textarea pre {
  margin-bottom: 0;
}

.form-floating.input_textarea label:before {
  background: none;
  box-shadow: none;
}

.form-select:disabled {
  color: lightgrey;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px;
  background: #dfdfdf;
  border-radius: 3px;
  border: 0px solid;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px;
  border: 0px solid;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  background: var(--bs-primary);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #dfdfdf;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px;
  background: #dfdfdf;
  border-radius: 3px;
  border: 0px solid;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 1px;
  border: 0px solid;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  background: var(--bs-primary);
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #dfdfdf;
  border: 0px solid;
  border-radius: 6px;
  box-shadow: 0px 0px 0px;
}

input[type=range]::-ms-fill-upper {
  background: #dfdfdf;
  border: 0px solid;
  border-radius: 6px;
  box-shadow: 0px 0px 0px;
}

input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 1px;
  border: 0px solid;
  height: 17px;
  width: 17px;
  border-radius: 3px;
  background: var(--bs-primary);
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #dfdfdf;
}

input[type=range]:focus::-ms-fill-upper {
  background: #dfdfdf;
}

/* error message formatting */
.alert-danger ul {
  margin-bottom: 0;
}

.help-text {
  font-size: 0.7rem;
  color: grey;
  word-break: break-all;
  display: none;
}

.nav-tabs .nav-link {
  z-index: 1;
}
.nav-tabs .nav-link.active {
  background-color: white !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}
.nav-tabs .tab-content {
  z-index: 1;
}
.nav-tabs li.nav-item:last-child::after {
  background-image: url(https://hosono.ai/images/logo/hosonoai-maskottchen.svg);
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  right: -2rem;
  top: 0;
  height: 3rem;
  width: 3rem;
  transform: rotate(24deg);
  z-index: 0;
}

.btn:hover .fa-plus {
  transition: var(--translate-smooth);
  transform: rotate(90deg) scale(1.3);
}
.btn.disabled {
  border: none;
}
.btn .fa-plus {
  transition: var(--translate-smooth);
  transform: rotate(0deg) scale(1);
}

a.btn:hover {
  text-decoration: none;
}

ul {
  list-style-position: inside;
}

#countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  font-size: 1.5rem;
  line-height: 1.5;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
}

.typing-container {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #000;
  position: relative;
}

.typing-text {
  white-space: nowrap;
  margin: 0;
  letter-spacing: 0.15em;
  animation: typing 2s steps(40) 1s forwards, blink-caret 0.75s step-end infinite;
}

.typing-cursor {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 1.5rem;
  margin-right: -0.5rem;
  background-color: #000;
  animation: blink-caret 50ms step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #000;
  }
}
div.header_information {
  z-index: 2;
}
div.header_information > div {
  aspect-ratio: 5/4;
}
@media (min-width: 576px) {
  div.header_information > div {
    aspect-ratio: 4/2;
  }
}
@media (min-width: 992px) {
  div.header_information > div {
    aspect-ratio: 3/1;
  }
}
div.header_information h1 {
  font-size: 1.75rem;
}
@media (min-width: 1200px) {
  div.header_information h1 {
    font-size: 3rem;
  }
}
div.header_information h1 span {
  font-size: 0.5em;
  font-weight: 400;
  line-height: 1.3;
}
div.header_information > p:not(.rating_header) {
  position: absolute;
}
div.header_information #main-image {
  transform: translateY(-50%);
}
div.header_information span.img_update {
  font-size: 0.8rem;
}
div.header_information:before {
  content: "";
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 992px) {
  div.header_information:before {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.4) 1%, rgba(0, 0, 0, 0.3) 34%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.4) 1%, rgba(0, 0, 0, 0.3) 34%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.4) 1%, rgba(0, 0, 0, 0.3) 34%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a6000000", endColorstr="#00000000",GradientType=1 );
  }
}

@media (min-width: 768px) {
  .col-md-12 > .post-grid,
  .col-lg-12 > .post-grid,
  .container > .post-grid,
  [class=col-xs-12] > .post-grid,
  [class=col-12] > .post-grid {
    width: 400px;
  }
}
.post-grid figure {
  background: #f7e8d3;
  height: auto;
  aspect-ratio: 3/2;
}
.post-grid figure:hover img {
  transition: all 400ms ease-in-out;
  transform: scale(1.1);
}
.post-grid figure:hover img.placeholder-post {
  animation: rotateAnimation 500ms infinite alternate;
}
.post-grid figure img {
  object-fit: cover;
  transition: all 400ms ease-in-out;
}
.post-grid figure figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.65);
}
.post-grid figure figcaption .h3 {
  font-size: 1rem;
  line-height: 1;
  margin: 0;
  padding: 0;
  color: white;
}
@media (min-width: 992px) {
  .post-grid figure figcaption .h3 {
    font-size: 1.1rem;
  }
}
.post-grid figure figcaption .h3 span {
  font-size: 75%;
  display: block;
  font-weight: 400;
}
.post-grid figure figcaption strong, .post-grid figure figcaption b, .post-grid figure figcaption i {
  color: white;
}
.post-grid figure .ort_label {
  transform: translateY(-100%);
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 0.75rem;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(10deg);
  }
}
.text-img {
  width: 45%;
  float: left;
  clear: left;
  margin: 1em;
  margin-left: 0;
  margin-top: 2%;
}
@media (min-width: 768px) {
  .text-img {
    clear: right;
    float: right;
    margin: 1em;
    margin-top: 1%;
    margin-right: 0;
    width: 280px;
  }
}
@media (min-width: 992px) {
  .text-img {
    width: 310px;
  }
}
.text-img img {
  border-radius: 0.375rem;
  overflow: hidden;
}
.text-img figcaption {
  margin-top: -0.25rem;
  font-size: 0.8rem;
  text-align: center;
}

.youtube {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.youtube iframe,
.youtube object,
.youtube video,
.youtube embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body {
  background: white;
}

footer .nav-link,
footer a {
  --bs-nav-link-color: white;
  --bs-nav-link-hover-color: white;
  color: white;
}
footer .nav-link:hover,
footer a:hover {
  color: white;
}

nav.navbar {
  --bs-navbar-color: black;
  box-shadow: var(--box-shadow);
  font-size: 0.8rem;
  z-index: 99999;
}
nav.navbar #logo {
  width: 125px;
}
@media (min-width: 992px) {
  nav.navbar #logo {
    width: 175px;
  }
}
nav.navbar .navbar-brand:hover {
  text-decoration: none;
}
nav.navbar .navbar-brand .icon {
  display: inline-block;
}
nav.navbar .nav-link {
  font-size: 0.9rem;
}
nav.navbar .dropdown-menu ul {
  font-size: 0.9rem;
}

.dropdown-hover:hover > .dropdown-menu {
  display: inline-block;
}

.dropdown-hover > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

body, html {
  overflow-x: hidden;
}

body main .c-details span {
  font-weight: 300;
  font-size: 13px;
}
body main .icon {
  width: 2rem;
  height: 2rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}
body main .badge span {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
body main .progress {
  height: 10px;
  border-radius: 10px;
}
body main .text1 {
  font-size: 14px;
  font-weight: 600;
}
body main .text2 {
  color: #a5aec0;
}
body main .tool-cards {
  transform: scale(1);
  transition: all 300ms ease-in-out 0s;
}
body main .tool-cards:hover {
  transform: scale(1.05);
  transition: all 300ms ease-in-out 0s;
}
body main .tool-cards small {
  min-height: 36px;
}

.cms .fa-circle-check,
.cms .fa-shield-check {
  color: #24B611;
}
.cms .fa-circle-xmark {
  color: #D10C19;
}
.cms .fa-triangle-exclamation {
  color: #E8A423;
}

.language-switcher-card {
  transition: all 0.3s ease-in-out;
}
.language-switcher-card:hover {
  background-color: var(--bs-primary);
  color: white;
  box-shadow: var(--bs-box-shadow-lg) !important;
  transition: all 0.3s ease-in-out;
}

.zutaten h3:first-child {
  margin-top: 0;
}
.zutaten .link-hidden {
  text-decoration: none;
  pointer-events: none;
}
.zutaten small {
  font-size: 0.7rem;
}
.zutaten .valueChanger {
  font-size: 90%;
}
.zutaten table {
  font-weight: 400;
  border-spacing: 0;
  border-collapse: collapse;
}
@media (min-width: 768px) {
  .zutaten table {
    font-size: 1em;
  }
}
.zutaten table th {
  text-align: left;
}
.zutaten table td {
  text-align: right;
  padding: 0.3em 0;
  white-space: nowrap;
}

.information {
  display: block;
}
.information .hot_nothot {
  width: 16px;
  height: auto;
  margin: 0;
  padding: 0;
}
.information table {
  font-weight: 400;
  border-spacing: 0;
  border-collapse: collapse;
}
@media (min-width: 768px) {
  .information table {
    font-size: 1em;
  }
}
.information table th {
  text-align: left;
}
.information table td {
  text-align: right;
}
.information ul {
  list-style-type: none;
  margin: 0.7em 0;
  margin-bottom: 2em;
  display: inline-block;
}
.information ul li {
  display: inline-block;
  width: 30px;
  height: auto;
}
.information ul li img {
  width: 100%;
}
.information div.rate_value {
  display: inline-block;
}

#preparation ol {
  list-style-type: none;
  counter-reset: section;
  margin: 0;
}
#preparation ol li {
  counter-increment: step-counter;
  position: relative;
  min-height: 1rem;
  border-bottom: 1px solid var(--bs-primary);
  margin-bottom: 1rem;
}
#preparation ol li:before {
  content: counter(step-counter);
  background-color: var(--bs-secondary);
  width: 25px;
  height: 25px;
  text-align: center;
  display: inline-block;
  color: white;
  position: absolute;
  top: 0.25rem;
  left: 0;
  border-radius: 3px;
}
#preparation ol li p {
  position: relative;
  display: block;
  counter-increment: section;
  margin-bottom: 1rem !important;
  padding: 0 1.25rem;
  padding-left: 2rem;
}