.zutaten {
    h3:first-child {
        margin-top: 0;
    }
    .link-hidden {
        text-decoration: none;
        pointer-events: none;
    }
    small {
        font-size: .7rem;
    }
    .valueChanger {
        font-size: 90%;
    }
    table {
        font-weight: 400;
        border-spacing: 0;
        border-collapse: collapse;
        @include media-breakpoint-up(sm) {
            font-size: 1em;
        }
        th {
            text-align: left;
        }
        td {
            text-align: right;
            padding: .3em 0;
            white-space: nowrap;
        }
    }
}
.information {
    display: block;
    .hot_nothot {
		width: 16px;
		height: auto;
		margin: 0;
		padding: 0;
	}
    table {
        font-weight: 400;
        border-spacing: 0;
        border-collapse: collapse;
        @include media-breakpoint-up(sm) {
            font-size: 1em;
        }
        th {
            text-align: left;
        }
        td {
            text-align: right;
        }
    }
    ul {
        list-style-type: none;
        margin: .7em 0;
        margin-bottom: 2em;
        display: inline-block;
        li {
            display: inline-block;
            width: 30px;
            height: auto;
            img {
                width: 100%;
            }
        }
    }
    div.rate_value {
        display: inline-block;
    }
}

#preparation {
    ol {
        list-style-type: none;
        counter-reset: section;
        margin: 0;
        li {
            counter-increment: step-counter;
            position: relative;
            min-height: 1rem;
            border-bottom: 1px solid var(--bs-primary);
            margin-bottom: 1rem;
            &:before {
                content: counter(step-counter);
                background-color: var(--bs-secondary);
                width: 25px;
                height: 25px;
                text-align: center;
                display: inline-block;
                color: white;
                position: absolute;
                top: .25rem;
                left: 0;
                border-radius: 3px;
            }
            p {
                position: relative;
                display: block;
                counter-increment: section;
                margin-bottom: 1rem!important;
                padding: 0 1.25rem;
                padding-left: 2rem;
            }
        }
    }
}