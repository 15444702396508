footer {
	.nav-link,
	a {
	  --bs-nav-link-color: white;
	  --bs-nav-link-hover-color: white;
	  color: white;
	  &:hover {
	  	color: white;
	  }
	}
}