.youtube {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	iframe,
	object,
	video,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}