/*
IE11 fixes
==================================================
Contains specific browser fixes which are only applied in IE.
*/

html.is-ie {
    // fix: wrong height and alignment of icons in input append buttons.
    .input-group-append {
        .icon {
            display: block;
        }

        svg {
            top: 0;
            vertical-align: auto;
        }
    }

    // fix: flexbox column is overlapped by another column which makes the select unclickable.
    .cart-item-row .cart-item-quantity {
        z-index: 1;
    }

    // fix: product image overflows image area because of flexbox
    .product-image-wrapper {
        flex-basis: auto;
    }

    .product-image-link {
        display: block;
    }

    .product-image {
        margin: auto;
    }
}
