:root {
	//PRIMARY
	--bs-primary: #273955;
	--bs-primary-hover: #36517D;
  
	//SECONDARY
	--bs-secondary: #b72515;
	--bs-secondary-hover: #e5331f;
  
	//PRIMARY
	--bs-primary-rgb: 39,57,85;
	--bs-primary-rgb-hover: 54, 81, 125;
  
	//SECONDARY
	--bs-secondary-rgb: 183,37,21;
	--bs-secondary-rgb-hover: 229, 51, 31;

	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-black: #000;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-gray-100: #f8f9fa;
	--bs-gray-200: #e9ecef;
	--bs-gray-300: #dee2e6;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-600: #6c757d;
	--bs-gray-700: #495057;
	--bs-gray-800: #343a40;
	--bs-gray-900: #212529;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-success-rgb: 25, 135, 84;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 255, 193, 7;
	--bs-danger-rgb: 220, 53, 69;
	--bs-light-rgb: 248, 249, 250;
	--bs-dark-rgb: 33, 37, 41;
	--bs-primary-text-emphasis: #052c65;
	--bs-secondary-text-emphasis: #2b2f32;
	--bs-success-text-emphasis: #0a3622;
	--bs-info-text-emphasis: #055160;
	--bs-warning-text-emphasis: #664d03;
	--bs-danger-text-emphasis: #58151c;
	--bs-light-text-emphasis: #495057;
	--bs-dark-text-emphasis: #495057;
	--bs-primary-bg-subtle: #cfe2ff;
	--bs-secondary-bg-subtle: #e2e3e5;
	--bs-success-bg-subtle: #d1e7dd;
	--bs-info-bg-subtle: #cff4fc;
	--bs-warning-bg-subtle: #fff3cd;
	--bs-danger-bg-subtle: #f8d7da;
	--bs-light-bg-subtle: #fcfcfd;
	--bs-dark-bg-subtle: #ced4da;
	--bs-primary-border-subtle: #9ec5fe;
	--bs-secondary-border-subtle: #c4c8cb;
	--bs-success-border-subtle: #a3cfbb;
	--bs-info-border-subtle: #9eeaf9;
	--bs-warning-border-subtle: #ffe69c;
	--bs-danger-border-subtle: #f1aeb5;
	--bs-light-border-subtle: #e9ecef;
	--bs-dark-border-subtle: #adb5bd;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: black;
	--bs-body-color-rgb: 0, 0, 0;
	--bs-body-bg: #fff;
	--bs-body-bg-rgb: 255, 255, 255;
	--bs-emphasis-color: #000;
	--bs-emphasis-color-rgb: 0, 0, 0;
	--bs-secondary-color: rgba(33, 37, 41, 0.75);
	--bs-secondary-color-rgb: 33, 37, 41;
	--bs-secondary-bg: #e9ecef;
	--bs-secondary-bg-rgb: 233, 236, 239;
	--bs-tertiary-color: rgba(33, 37, 41, 0.5);
	--bs-tertiary-color-rgb: 33, 37, 41;
	--bs-tertiary-bg: #f8f9fa;
	--bs-tertiary-bg-rgb: 248, 249, 250;
	--bs-heading-color: inherit;
	--bs-link-color: var(--bs-secondary);
	--bs-link-color-rgb: var(--bs-secondary-rgb);
	--bs-link-decoration: underline;
	--bs-link-hover-color: var(--bs-secondary-hover);
	--bs-link-hover-color-rgb: var(--bs-secondary-rgb-hover);
	--bs-code-color: #d63384;
	--bs-highlight-color: #212529;
	--bs-highlight-bg: #fff3cd;
	--bs-border-width: 1px;
	--bs-border-style: solid;
	--bs-border-color: #dee2e6;
	--bs-border-color-translucent: rgba(0, 0, 0, 0.175);
	--bs-border-radius: 0.375rem;
	--bs-border-radius-sm: 0.25rem;
	--bs-border-radius-lg: 0.5rem;
	--bs-border-radius-xl: 1rem;
	--bs-border-radius-xxl: 2rem;
	--bs-border-radius-2xl: var(--bs-border-radius-xxl);
	--bs-border-radius-pill: 50rem;
	--bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	--bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
	--bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
	--bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
	--bs-focus-ring-width: 0.25rem;
	--bs-focus-ring-opacity: 0.25;
	--bs-focus-ring-color: rgba(13, 110, 253, 0.25);
	--bs-form-valid-color: #198754;
	--bs-form-valid-border-color: #198754;
	--bs-form-invalid-color: #dc3545;
	--bs-form-invalid-border-color: #dc3545;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-hover);
  --bs-btn-hover-border-color: var(--bs-primary-hover);
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #FFA2C3;
  --bs-btn-disabled-border-color: #FFA2C3;
}

.btn-secondary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--bs-secondary);
    --bs-btn-border-color: var(--bs-secondary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bs-secondary-hover);
    --bs-btn-hover-border-color: var(--bs-secondary-hover);
    --bs-btn-focus-shadow-rgb: 130,138,145;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bs-secondary);
    --bs-btn-active-border-color: var(--bs-secondary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bs-secondary);
    --bs-btn-disabled-border-color: var(--bs-secondary);
}

.list-group {
  --bs-list-group-active-bg: var(--bs-primary);
  --bs-list-group-active-border-color: var(--bs-primary);
  --bs-list-group-item-padding-y: 0.4rem;
}

.accordion {
    --bs-accordion-color: #000;
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: var(--bs-body-color);
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    --bs-accordion-btn-focus-border-color: var(--bs-primary);
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: white;
    --bs-accordion-active-bg: var(--bs-primary);
}