.amazonbox {
	position: relative;
	margin: 2.25rem auto;
    width: 100%;
	@include media-breakpoint-up(md) {
		margin: 2.25rem auto;
		width: 75%;
	}
	&:before {
		content: "Unsere Empfehlung";
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: var(--bs-secondary);
		color: white;
		z-index: 999;
		font-size: .75rem;
        padding: 0.5rem;
        border-radius: 3px;
	}
	.amazonbox_image {
		border: none;
		max-height: 125px;
		@include media-breakpoint-up(md) {
			max-height: 200px;
		}
		img {
			width: auto;
			max-height: 100px;
			margin: 0 auto;
			@include media-breakpoint-up(md) {
				max-height: 150px;
				max-width: 85%;
			}
		}
	}
	.amazonbox_text {
		strong {
			display: block;
			padding: 0;
			font-size: .9rem;
			font-weight: bold;
			@include media-breakpoint-up(md) {
				font-size: .85rem;
			}
		}
		p {
			font-size: .8rem;
			line-height: 1.3;
			@include media-breakpoint-up(md) {
				font-size: .75rem;
			}
		}
		a.btn {
			font-size: .75rem;
		}
	}
}
.col-md-6 .amazonbox {
	@include media-breakpoint-up(md) {
		width: 95%;
		min-height: 250px;
	}
}