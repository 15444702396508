nav.navbar {
    --bs-navbar-color: black;
    box-shadow: var(--box-shadow);
    font-size: .8rem;
    z-index: 99999;
    #logo {
        width: 125px;
        @include media-breakpoint-up(md) {
            width: 175px;
        }
    }
    .navbar-brand {
        &:hover {
            text-decoration: none;
        }

        .icon {
            display: inline-block;
        }
    }
    .nav-link {
        font-size: .9rem;
    }
    .dropdown-menu {
        ul {
            font-size: .9rem;
        }
    }
}

.dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
}

.dropdown-hover>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}