body {
	font-family: sans-serif;
}

p {
    line-height: 1.7;
}

a {
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

strong,b {
    font-weight: bold;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	font-weight: 500!important; //700 is too much
    font-family: sans-serif;
}

h1,.h1 {
    font-size: 2.5rem;
}

h2,.h2 {
    font-size: 2rem;
}

h3,.h3 {
    font-size: 1.75rem;
}

h4,.h4 {
    font-size: 1.5rem;
}

h5,h6,.h5,.h6 {
    font-size: 1.2rem;
}

ol,ul {
    padding-left: 0;
}