.list-group-item,
.card {
	border: none;
}

.shadow-sm {
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.1)!important;
}


// https://github.com/twbs/bootstrap/issues/32800#issuecomment-989827103
.form-floating.input_textarea label {
    min-width: 90%;
}

    .form-floating.input_textarea label::before {
        content: "";
        position: absolute;
        top: 0.9em;
        z-index: -1;
        width: 100%;
        height: 1.2em;
        background-color: white;
        box-shadow: 0 0 8px 4px #ffffff;
    }


.form-floating.input_textarea > .form-control:focus ~ label, .form-floating.input_textarea > .form-control:not(:placeholder-shown) ~ label, .form-floating.input_textarea > .form-select ~ label {
    opacity: 0.95;
    color: gray;
}