.nav-tabs {
	.nav-link{
		&.active {
			background-color: white!important;
			border-color: var(--bs-primary)!important;
			color: var(--bs-primary)!important;
		}
		z-index: 1;
	}
	.tab-content {
		z-index: 1;
	}
	li.nav-item:last-child::after {
	    background-image: url(https://hosono.ai/images/logo/hosonoai-maskottchen.svg);
	    background-repeat: no-repeat;
	    content: "";
	    position: absolute;
	    right: -2rem;
	    top: 0;
	    height: 3rem;
	    width: 3rem;
	    transform: rotate(24deg);
	    z-index: 0;
	}
}