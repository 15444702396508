.btn {
	&:hover {
		.fa-plus {
			transition: var(--translate-smooth);
			transform: rotate(90deg) scale(1.3);
		}
	}
	
	&.disabled {
		border: none;
	}

	.fa-plus {
		transition: var(--translate-smooth);
		transform: rotate(0deg) scale(1);
	}	
}
a.btn {
	&:hover {
		text-decoration: none;
	}
}