ul.breadcrumbs {
    list-style-type: none;
    z-index: 99;
    position: relative;
    display: inline-block;
    font-size: 1rem;
    li {
        display: inline-block;
        float: left;
        color: var(--bs-secondary);
        font-weight: 700;
        &:first-child a {
            padding-left: 0;
        }
        a {
            padding: 0 0.3em;
            color: var(--bs-secondary);
            &:hover {
                text-decoration: underline;
            }
        }
    }
}