.description,
#preparation // Also use the same styles for the recipe preparations
{
	h1,h2,h3,h4,h5,h6,
	.h1,.h2,.h3,.h4,.h5,.h6 {
		line-height: 1.25;
		margin-bottom: 1rem;
	}
	figcaption .h6 {
		margin-bottom: 0;
	}
	figure {
		border-radius: var(--bs-border-radius);
		overflow: hidden;
		figcaption {
			--bs-bg-opacity: 1;
			--bs-text-opacity: 1;
			background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
			color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
			padding-top: .5rem;
			padding-bottom: .5rem;
			padding-right: 1rem;
			padding-left: 1rem;
		}
	}
	table {
		--bs-table-color-type: initial;
		--bs-table-bg-type: initial;
		--bs-table-color-state: initial;
		--bs-table-bg-state: initial;
		--bs-table-color: var(--bs-emphasis-color);
		--bs-table-bg: var(--bs-body-bg);
		--bs-table-border-color: var(--bs-border-color);
		--bs-table-accent-bg: transparent;
		--bs-table-striped-color: var(--bs-emphasis-color);
		--bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
		--bs-table-active-color: var(--bs-emphasis-color);
		--bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
		--bs-table-hover-color: var(--bs-emphasis-color);
		--bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
		width: 100%;
		margin-bottom: 1rem;
		vertical-align: top;
		border-color: var(--bs-table-border-color);
		&>:not(caption)>*>* {
			padding: .5rem 0;
			color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
			background-color: var(--bs-table-bg);
			border-bottom-width: var(--bs-border-width);
			box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
		}
	}
	ul,ol {
		margin: 1rem 0;
		list-style-position: inside;
		li {
			margin-bottom: .5rem;
			p {
				display: inline;
			}
		}
	}
	img {
		margin-bottom: 1rem;
		&.pswp-gallery__trigger {
			cursor: zoom-in;
		}
	}
	figure img {
		margin-bottom: 0; // No margin for figures
	}
}