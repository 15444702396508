.feedback-btn {
    position: fixed;
    top: 50%;
    left: calc(100% - 75px);
    transform: rotate(-90deg);
    height: auto;
    width: auto;
    z-index: 10;
    white-space: nowrap;
    display: inline-block;
}