.cms {
	.fa-circle-check,
	.fa-shield-check {
		color: #24B611;
	}
	.fa-circle-xmark {
		color: #D10C19;
	}
	.fa-triangle-exclamation {
		color: #E8A423;
	}
}