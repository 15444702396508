#countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 100%);
  font-size: 1.5rem;
  line-height: 1.5;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.9);
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
}

.typing-container {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #000;
  position: relative;
}

.typing-text {
  white-space: nowrap;
  margin: 0;
  letter-spacing: 0.15em;
  animation: typing 2s steps(40) 1s forwards, blink-caret 0.75s step-end infinite;
}

.typing-cursor {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 1.5rem;
  margin-right: -.5rem;
  background-color: #000;
  animation: blink-caret 50ms step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #000 }
}
