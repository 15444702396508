div.header_information {
    z-index: 2;
    &> div {
        aspect-ratio:5/4;
        @include media-breakpoint-up(xs) {
            aspect-ratio:4/2;
        }
        @include media-breakpoint-up(md) {
            aspect-ratio:3/1;
        }
    }
    h1 {
        font-size: 1.75rem;
        @include media-breakpoint-up(lg) {
            font-size: 3rem;
        }
        span {
            font-size: .5em;
            font-weight: 400;
            line-height: 1.3;
        }
    }
    &> p:not(.rating_header) {
        position: absolute;
    }
    #main-image {
        transform: translateY(-50%);
    }
    span.img_update {
        font-size: .8rem;
    }
    &:before {
        content: "";
        z-index: 3;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
        @include media-breakpoint-up(md) {
            background: -moz-linear-gradient(left, rgba(0,0,0,0.45) 0%, rgba(0,0,0,0.4) 1%, rgba(0,0,0,0.3) 34%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(left, rgba(0,0,0,0.45) 0%,rgba(0,0,0,0.4) 1%,rgba(0,0,0,0.3) 34%,rgba(0,0,0,0) 100%);
            background: linear-gradient(to right, rgba(0,0,0,0.45) 0%,rgba(0,0,0,0.4) 1%,rgba(0,0,0,0.3) 34%,rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=1 );
        }
    }
}